<template>
  <div>
    <div class="hit">
      <span class="num" v-if="hitNumber">{{ hit }}</span>
      <LotteryNumberItem
        v-for="(item, index) in hitList"
        :key="index"
        :ticket="item"
        :hitNumber="hitNumber"
      ></LotteryNumberItem>
    </div>
    <div class="tips" v-if="!hitNumber">
      <i class="iconfont icon-gantanhao-xianxingyuankuang"></i>
      <span @click="$gbUtils.handleToPage('/lotteryRule')">{{
        pop.whyNot
      }}</span>
    </div>
  </div>
</template>

<script>
import LotteryNumberItem from './LotteryNumberItem.vue'
export default {
  components: { LotteryNumberItem },
  name: '',
  props: {
    hitNumber: {
      type: Number,
      default: 0
    },
    hitList: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data () {
    return {

    }
  },
  computed: {
    pop () {
      return this.$t('lottery.pop')
    },
    hit (number) {
      return this.$t('lottery.pop.hit', { number: this.hitNumber })
    }
  },
  methods: {

  },
  mounted () {

  }
}
</script>

<style scoped lang="scss">
.hit {
  .num {
    display: flex;
    justify-content: flex-end;
    font-weight: bold;
    color: #a85ffc;
    font-size: 12px;
    padding-bottom: 5px;
  }
}
.tips {
  padding-top: 20px;
  font-size: 15px;
  text-align: center;
  color: #7b8fbb;
  span {
    cursor: pointer;
    margin-left: 5px;
    text-decoration: underline;
  }
}
</style>
