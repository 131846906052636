<template>
  <!-- 中獎詳情 -->
  <VanPopup
    v-model="popupShow"
    @click-overlay="closePop"
    style="width: 93%; max-width: 350px"
    round
    get-container="content"
  >
    <div class="wrapper">
      <div class="header">
        <div class="title">{{$t('lottery.history.checkResult')}}</div>
        <div class="close-wrapper">
          <div class="close-icon" @click="closePop">✕</div>
        </div>
      </div>
      <div class="content">
        <div class="notice">
          {{pop.detailNotice}}
        </div>
        <div class="hash-title">{{pop.detailTitle}}</div>
        <div class="hash" v-html="hash"></div>
        <div class="button" @click="closePop">{{pop.ok}}</div>
      </div>
    </div>
  </VanPopup>
</template>

<script>
export default {
  data () {
    return {
      popupShow: true,
      num: '152356',
      hash: '0x98171F50E0Bf47ce5a403985302c4f608e8bbf76'
    }
  },
  computed: {
    pop () {
      return this.$t('lottery.pop')
    }
  },
  methods: {
    // 关闭弹窗
    closePop () {
      this.$emit('close')
    },
    getFinalSixNumber (str) {
      let result
      let length = 6
      let wholeStr
      do {
        length++
        result = str.slice(-length).replace(/[^0-9]/ig, '')
        wholeStr = str.slice(-length)
      } while (result.length < 6)
      let newstr = ''
      wholeStr = wholeStr.split('')
      for (let i = 0; i < wholeStr.length; i++) {
        if (wholeStr[i].match(/^\d+$/)) {
          wholeStr[i] = `<span style='color: #A85FFC;'>${wholeStr[i]}</span>`
        }
        newstr += wholeStr[i]
      }
      console.log(str.slice(0, str.length - length) + newstr)
      return `<span style=' border-bottom:1px solid #000;word-wrap:break-word'>${str.slice(0, str.length - length) + newstr}</span>`
    }
  },
  mounted () {
    this.hash = this.getFinalSixNumber(this.hash)
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  min-height: 300px;
  max-height: 80vh;
  padding-bottom: 30px;
  box-sizing: border-box;
  .content {
    padding: 0 30px;
    box-sizing: border-box;
  }
  .header {
    padding: 15px 30px;
    border-bottom: 1px solid #e3e3e3;
    height: 40px;
    line-height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 18px;
    font-weight: 700;
    .title {
      display: flex;
      align-items: center;
    }
    span {
      display: inline-block;
      width: 55px;
      height: 25px;
      background: rgba(168, 95, 252, 0.05);
      border: 1px solid rgba(168, 95, 252, 0.5019607843137255);
      border-radius: 15px;
      line-height: 25px;
      text-align: center;
      margin-left: 10px;
      font-weight: normal;
      font-size: 14px;
    }
    .close-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      .close-icon {
        box-sizing: border-box;
        cursor: pointer;
        width: 20px;
        height: 20px;
        background: #66eab9 !important;
        border-radius: 10px;
        text-align: center;
        line-height: 20px;
        font-size: 12px;
        font-weight: 700;
        color: #fff;
      }
    }
  }
  .notice {
    padding-top: 30px;
    font-size: 12px;
    color: #4c5968;
    line-height: 18px;
  }
  .hash-title {
    padding-top: 20px;
    font-size: 12px;
    color: #2a2b33;
  }
  .hash {
    padding-top: 20px;
    font-size: 15px;
    font-weight: bold;
    color: #2a2b33;
  }
  .button {
    margin-top: 30px;
    height: 50px;
    background: #66eab9;
    border-radius: 15px;
    font-weight: 700;
    line-height: 50px;
    cursor: pointer;
    text-align: center;
    font-size: 15px;
  }
}
</style>
