<template>
  <div class="item">
    <div class="title">{{ ticket.number }}</div>
    <div class="num">
      <span
        v-for="(item, index) in ticket.value"
        :key="index"
        :class="{ hit: index + 1 <= hitNumber }"
        >{{ item }}</span
      >
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  props: {
    hitNumber: {
      type: Number,
      default: 0
    },
    ticket: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {

    }
  },
  methods: {

  },
  mounted () {

  }
}
</script>

<style scoped lang="scss">
.item {
  display: flex;
  align-content: center;
  height: 30px;
  line-height: 30px;
  margin-bottom: 10px;
  justify-content: space-between;
  .num {
    box-sizing: border-box;
    background: #f0f0f0;
    width: 80%;
    border-radius: 15px;
    display: flex;
    color: #a2a2a2;
    justify-content: space-around;
    padding: 0 20px;
  }
  .hit {
    color: #a85ffc;
    font-weight: bold;
  }
}
</style>
