import { render, staticRenderFns } from "./CheckIsDraw.vue?vue&type=template&id=3ad5d063&scoped=true&"
import script from "./CheckIsDraw.vue?vue&type=script&lang=js&"
export * from "./CheckIsDraw.vue?vue&type=script&lang=js&"
import style0 from "./CheckIsDraw.vue?vue&type=style&index=0&id=3ad5d063&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ad5d063",
  null
  
)

export default component.exports