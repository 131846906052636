<template>
  <div class="history-page">
    <div class="history-wrap">
      <div class="top">
        <div class="_flex">
          <!-- 左侧回合 -->
          <div class="left">
            <span>{{ history.Round }}</span>
            <span class="num-box">325</span>
          </div>
          <!-- 右侧箭头翻页 -->
          <div class="right" v-if="allFlag">
            <div
              class="sc-hKFxyN fgKIwT sc-eCApnc sc-cRgdMP fAYovO cRIPkl"
              scale="sm"
            >
              <svg
                viewBox="0 0 24 24"
                color="text"
                width="20px"
                xmlns="http://www.w3.org/2000/svg"
                class="sc-bdnxRM kDWlca"
              >
                <path
                  d="M19 11H7.82998L12.71 6.12C13.1 5.73 13.1 5.09 12.71 4.7C12.32 4.31 11.69 4.31 11.3 4.7L4.70998 11.29C4.31998 11.68 4.31998 12.31 4.70998 12.7L11.3 19.29C11.69 19.68 12.32 19.68 12.71 19.29C13.1 18.9 13.1 18.27 12.71 17.88L7.82998 13H19C19.55 13 20 12.55 20 12C20 11.45 19.55 11 19 11Z"
                ></path>
              </svg>
            </div>
            <div
              class="sc-hKFxyN fgKIwT sc-eCApnc sc-cRgdMP fAYovO cRIPkl"
              scale="sm"
            >
              <svg
                viewBox="0 0 24 24"
                color="text"
                width="20px"
                xmlns="http://www.w3.org/2000/svg"
                class="sc-bdnxRM kDWlca"
              >
                <path
                  d="M5 13H16.17L11.29 17.88C10.9 18.27 10.9 18.91 11.29 19.3C11.68 19.69 12.31 19.69 12.7 19.3L19.29 12.71C19.68 12.32 19.68 11.69 19.29 11.3L12.71 4.7C12.32 4.31 11.69 4.31 11.3 4.7C10.91 5.09 10.91 5.72 11.3 6.11L16.17 11H5C4.45 11 4 11.45 4 12C4 12.55 4.45 13 5 13Z"
                ></path>
              </svg>
            </div>
            <div
              class="sc-hKFxyN jYLfuR sc-eCApnc sc-cRgdMP fAYovO cRIPkl"
              scale="sm"
            >
              <svg
                viewBox="0 0 24 24"
                color="text"
                width="20px"
                xmlns="http://www.w3.org/2000/svg"
                class="sc-bdnxRM kDWlca"
              >
                <path
                  d="M3 13.1835H14.17L9.29 18.0635C8.9 18.4535 8.9 19.0935 9.29 19.4835C9.68 19.8735 10.31 19.8735 10.7 19.4835L17.29 12.8935C17.68 12.5035 17.68 11.8735 17.29 11.4835L10.71 4.88347C10.32 4.49347 9.69 4.49347 9.3 4.88347C8.91 5.27347 8.91 5.90347 9.3 6.29347L14.17 11.1835H3C2.45 11.1835 2 11.6335 2 12.1835C2 12.7335 2.45 13.1835 3 13.1835Z"
                ></path>
                <path
                  d="M20 5.18347C20.5523 5.18347 21 5.63119 21 6.18347V18.1835C21 18.7358 20.5523 19.1835 20 19.1835C19.4477 19.1835 19 18.7358 19 18.1835V6.18347C19 5.63119 19.4477 5.18347 20 5.18347Z"
                ></path>
              </svg>
            </div>
          </div>
        </div>
        <!-- 时间 -->
        <div class="time">
          <van-skeleton title :loading="loading">
            {{ history.Drawn }} 2021年12月27日 上午8:00
          </van-skeleton>
        </div>
      </div>
      <!-- 中獎框 -->
      <div class="number-box">
        <!-- 标题 -->
        <div class="title">{{ history.WinningNumber }}</div>
        <!-- 中奖号码 -->
        <div class="number-wrap">
          <span class="number" v-for="item in mumberArray" :key="item">{{
            item
          }}</span>
        </div>
        <!-- 单个回合，有彩票 -->
        <div class="my-round" v-if="!allFlag">
          <div class="title">
            <span
              v-html="
                $t('lottery.main.haveTickets', { number: roundObj.number })
              "
            ></span>
          </div>
          <div class="check-cp">
            <span @click="handleToCheck">{{ main.viewTickets }}</span>
          </div>
        </div>
        <!-- 歷史紀錄，查看開獎詳情 -->
        <div class="my-round check-cp" v-else>
          <span @click="handleToDetail">{{ history.checkResult }}</span>
        </div>
        <!-- 最新標識 -->
        <div class="new-flag" v-if="lastest">{{ history.Latest }}</div>
      </div>
      <!-- 獎金池 -->
      <div class="reward-wrap">
        <div class="title">{{ history.Prizepot }}</div>
        <div class="all-price">~$198,436</div>
        <div class="all-meb">2341243.45 MEB</div>
        <div class="all-user">{{ history.totalPlayer }}<span>3125</span></div>
        <div class="tips">{{ history.rules }}</div>
        <!-- 命中位數獎勵 -->
        <NumMatch></NumMatch>
      </div>
    </div>
    <LotteryDetail
      v-if="lotteryDetailIsShow"
      @close="handleToDetail"
    ></LotteryDetail>
    <CheckIsDraw v-if="checkLotteryIsShow" @close="handleToCheck"></CheckIsDraw>
  </div>
</template>
<script>
import i18n from '@/main.js'
import NumMatch from '../components/NumMatch.vue'
import CheckIsDraw from '../components/CheckIsDraw.vue'
import LotteryDetail from './components/LotteryDetail.vue'
export default {
  components: { CheckIsDraw, NumMatch, LotteryDetail },
  name: 'LotteryHistory',
  data () {
    return {
      checkLotteryIsShow: false,
      lotteryDetailIsShow: false,
      roundId: '', // 回合id
      allFlag: false, // 统计
      lastest: true, // 最新回合标识
      loading: false,
      number: '268579',
      roundObj: {
        number: '5',
        tickes: [
          {
            number: '#001',
            value: '268579'
          },
          {
            number: '#002',
            value: '123125'
          },
          {
            number: '#003',
            value: '26521'
          },
          {
            number: '#005',
            value: '268500'
          },
          {
            number: '#006',
            value: '268570'
          }
        ]
      },
      hitNumber: 0,
      hitList: {
        noneList: [],
        oneList: [],
        twoList: [],
        threeList: [],
        fourList: [],
        fiveList: [],
        sixList: []
      }
    }
  },
  computed: {
    mumberArray () {
      return this.number.split('')
    },
    main () {
      return this.$t('lottery.main')
    },
    history () {
      return this.$t('lottery.history')
    }
  },
  filters: {
    filterMatch (value) {
      return i18n.t('lottery.history.MatchFirst', { number: value })
    }
  },
  methods: {
    getRoundId () {
      const roundId = this.$route.query.roundId || ''
      if (roundId) {
        this.allFlag = false
        this.roundId = roundId
      } else {
        this.allFlag = true
      }
    },
    getMatch (value) {
      return i18n.t('lottery.history.MatchFirst', { number: value })
    },
    handleToCheck () {
      this.checkLotteryIsShow = !this.checkLotteryIsShow
    },
    handleToDetail () {
      this.lotteryDetailIsShow = !this.lotteryDetailIsShow
    },
    matchTickets () {
      this.roundObj.tickes.forEach((item) => {
        if (item.value === this.number) {
          this.hitList.sixList.push(item)
        } else if (item.value.slice(0, 5) === this.number.slice(0, 5)) {
          this.hitList.fiveList.push(item)
        } else if (item.value.slice(0, 4) === this.number.slice(0, 4)) {
          this.hitList.fourList.push(item)
        } else if (item.value.slice(0, 3) === this.number.slice(0, 3)) {
          this.hitList.threeList.push(item)
        } else if (item.value.slice(0, 2) === this.number.slice(0, 2)) {
          this.hitList.twoList.push(item)
        } else if (item.value.slice(0, 1) === this.number.slice(0, 1)) {
          this.hitList.oneList.push(item)
        } else {
          this.hitList.noneList.push(item)
        }
      })
      console.log(this.hitList)
    }
  },
  mounted () {
    this.getRoundId()
    this.matchTickets()
  }
}
</script>
<style lang="scss" scoped>
.history-page {
  width: 100%;
  height: 100vh;
  background: #c5d1eb;
  padding: 30px 18px;
  box-sizing: border-box;
  overflow: scroll;
  .history-wrap {
    width: 100%;
    min-height: 600px;
    background: #ffffff;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
    opacity: 1;
    border-radius: 10px;
    padding: 18px;
    padding-bottom: 50px;
    box-sizing: border-box;
    font-size: 15px;
    color: #4c5968;
    // part 1
    .top {
      margin-bottom: 20px;
      ._flex {
        display: flex;
        justify-content: space-between;
      }
      .left {
        color: #2a2b33;
        font-weight: bold;
        font-size: 18px;
        .num-box {
          font-weight: normal;
          margin-left: 8px;
          border: 1px solid #a85ffc;
          background: rgba(168, 95, 252, 0.05);
          border-radius: 15px;
          padding: 2px 15px;
          box-sizing: border-box;
          font-size: 15px;
        }
      }
      .right {
        display: flex;
        div {
          margin-left: 20px;
        }
      }
      .time {
        margin-top: 10px;
        color: #4c5968;
      }
    }
    // part 2
    .number-box {
      min-height: 125px;
      border-top: 1px dashed #e3e3e3;
      border-bottom: 1px dashed #e3e3e3;
      padding: 20px 0;
      box-sizing: border-box;
      position: relative;
      text-align: center;
      overflow: hidden;
      margin-bottom: 20px;
      .title {
        font-weight: bold;
        color: #4c5968;
        margin-bottom: 20px;
      }
      .number-wrap {
        text-align: center;
        .number {
          display: inline-block;
          width: 30px;
          height: 30px;
          line-height: 30px;
          text-align: center;
          border-radius: 50%;
          font-size: 18px;
          font-weight: bold;
          color: #ffffff;
          text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.25);
          margin: 0 3px;
        }
        :first-child {
          background: #a85ffc;
        }
        :nth-child(2) {
          background: #2cb2ee;
        }
        :nth-child(3) {
          background: #25e2b1;
        }
        :nth-child(4) {
          background: #ea3f3f;
        }
        :nth-child(5) {
          background: #ffb426;
        }
        :nth-child(6) {
          background: #ea33e3;
        }
      }
      .new-flag {
        z-index: 10;
        background-color: #a85ffc;
        color: white;
        margin: 0px;
        padding: 8px 0px;
        position: absolute;
        right: -30px;
        top: 15px;
        text-align: center;
        transform: rotate(45deg);
        // transform-origin: left top;
        width: 130px;
      }
      .my-round {
        margin-top: 20px;
        .title {
          font-size: 15px;
          font-weight: 400;
          color: #2a2b33;
          margin-bottom: 5px;
        }
      }
      .check-cp {
        font-size: 15px;
        span {
          color: #7b8fbb;
          // text-decoration: underline;
          border-bottom: 1px solid #7b8fbb;
        }
      }
    }
  }
  // part 3
  .reward-wrap {
    .title {
      color: #2a2b33;
      font-size: 18px;
      margin-bottom: 10px;
    }
    .all-price {
      color: #a85ffc;
      font-weight: bold;
      font-size: 35px;
      line-height: 42px;
      margin-bottom: 5px;
    }
    .all-meb {
      font-size: 12px;
      color: #4c5968;
      line-height: 14px;
    }
    .all-user {
      color: #4c5968;
      margin-bottom: 20px;
      span {
        font-weight: bold;
      }
    }
    .hit-wrap {
      display: flex;
      flex-wrap: wrap;
    }
    .hit-box {
      width: 50%;
      margin: 20px auto 0 0;
      .title {
        color: #a85ffc;
        font-size: 15px;
        font-weight: 400;
        line-height: 1.5;
        margin-bottom: 0;
      }
      .meb-number {
        font-size: 20px;
        font-weight: bold;
        line-height: 1.5;
        color: #4c5968;
      }
      .meb-value {
        color: #818086;
        font-size: 12px;
        line-height: 1;
      }
    }
    .dao-box {
      width: 100%;
      .title {
        font-weight: bold;
      }
    }
  }
}
</style>
